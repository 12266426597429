import { atcb_action as Atcb } from 'add-to-calendar-button'
import moment from 'moment-timezone'
import 'add-to-calendar-button/assets/css/atcb.css'

const addToCalendar = ({ user, job }) => {
  const meetingAt = moment.tz(job.meetingAt, user.timeZone)
  const separator = '-'.repeat(20)
  const icsContent = {
    name: `Dexter | ${job.project.title}`,
    description: `Project Info: ${job.project.title}\n${
      job.project.description
    }${
      job.project.preInterviewLink
        ? `\nPre-interview material is available at ${job.project.preInterviewLink}`
        : ''
    }\n${separator}\n${job.meetingInvite}`,
    organizer: 'Dexter|help@dexterexperts.com',
    startDate: meetingAt.format('YYYY-MM-DD'),
    startTime: meetingAt.format('HH:mm'),
    endTime: meetingAt.add(1, 'hour').format('HH:mm'),
    timeZone: user.timeZone,
    location: job.meeting_link,
    options: [
      'Apple',
      'Google',
      'iCal',
      'Microsoft365',
      'Outlook.com',
      'Yahoo',
    ],
    duration: '1H',
    trigger: 'click',
    iCalFileName: `Dexter-${job.project.title}`,
  }

  return Atcb(icsContent)
}
export default addToCalendar
