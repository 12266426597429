import React, { useState, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Modal, Button, Loader } from 'semantic-ui-react'
import { LOAD_ME_QUERY } from '../../../graphql/user'
import { PROJECT_CALENDAR } from '../../../graphql/project'
import { SCHEDULE_MEETING, RESCHEDULE_MEETING } from '../../../graphql/schedule'
import { UPDATE_JOB_MUTATION } from '../../../graphql/job'
import MessageContext from '../../../contexts/MessageContext'

import SchedulerConent from './SchedulerContent'

const Scheduler = ({ onNext, onCancel, job, advisor }) => {
  const { loading, data, refetch } = useQuery(PROJECT_CALENDAR, {
    variables: { id: job.project.id },
  })
  const [selectedTime, setSelectedTime] = useState('')

  const { setAlertMsg } = useContext(MessageContext)

  const [updateJob] = useMutation(UPDATE_JOB_MUTATION)

  const [rescheduleMeeting, { loading: rescheduling }] =
    useMutation(RESCHEDULE_MEETING)
  const [scheduleMeeting, { loading: processing }] = useMutation(
    SCHEDULE_MEETING,
    {
      refetchQueries: [{ query: LOAD_ME_QUERY }],
      onCompleted: () => {
        onNext()
      },
      onError: (error) => {
        if (
          error.message ===
          'This meeting could not be scheduled due to insufficient credits'
        ) {
          return setAlertMsg({
            title: 'Failed to accept the invitation',
            content:
              'This project is currently on hold. Please check again after some time or contact us at help@dexterexperts.com for more information.',
            onClose: () => refetch(),
          })
        }
        setAlertMsg({
          title: 'Error',
          content: error.message,
          onClose: () => refetch(),
        })
      },
    }
  )

  const onReschedule = () => {
    rescheduleMeeting({
      variables: { jobId: job.id },
      update: () => onSchedule(),
    })
  }

  const onSchedule = async () => {
    if (selectedTime === '') {
      return setAlertMsg({
        title: 'Error',
        content: 'You need to select a time slot',
      })
    }

    // Save the job.qualificationResponses to database
    // This qualificationResponses field is attached to the job that passed as a prop from regular scheduling workflow
    if (job.qualificationResponses.length > 0) {
      await updateJob({
        variables: {
          userId: advisor.id,
          projectId: job.project.id,
          job: {
            qualificationResponses: job.qualificationResponses,
          },
        },
      })
    }

    await scheduleMeeting({
      variables: {
        jobId: job.id,
        startTime: selectedTime,
      },
    })
    onNext()
  }
  if (loading) return <Loader active />
  return (
    <Modal closeIcon open onClose={onCancel}>
      <Modal.Header>{`Schedule meeting time for ${job.project.title}`}</Modal.Header>
      <Modal.Content>
        <SchedulerConent
          project={data.project}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          loading={processing || rescheduling}
          disabled={processing || rescheduling}
          onClick={job.meetingAt ? onReschedule : onSchedule}
        >
          Submit
        </Button>
        <Button
          loading={processing || rescheduling}
          disabled={processing || rescheduling}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default Scheduler
